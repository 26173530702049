import React, { useEffect, useState } from 'react';
import { Container, Loader } from '../components/tables/Style';
import { AddButton } from '../components/tables/Buttons';
import { queryCollectionByDocId } from '../services/database';
import styled from 'styled-components';
import { getAllCollection, addToCollection } from '../services/database';


// Stilizirani elementi
const StyledInput = styled.input`
    width: 100%;
    height: 40px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
`;

const StyledCheckbox = styled.input`
    margin: 10px;
`;

const StyledH1 = styled.h1`
    color: #fff;
    font-size: 18px;
    margin: 15px 0 5px;
`;

const EditSlider = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        index: '',
        image_url: '',
        title: '',
        link: '',
        subtitle: '',
        order_number: '',
        active: false,
    });
    const [showAddRow, setShowAddRow] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (history.location.state && history.location.state.id) {
            setLoading(true);
            console.log('Primljeni ID za dohvaćanje dokumenta:', history.location.state.id); // Log za provjeru

            queryCollectionByDocId('slider_items', history.location.state.id)
                .then((result) => {
                    if (result.data) {
                        setInputs({
                            index: result.data.index || '',
                            image_url: result.data.image_url || '',
                            title: result.data.title || '',
                            link: result.data.link || '',
                            subtitle: result.data.subtitle || '',
                            order_number: result.data.order_number || '',
                            active: result.data.active || false,
                        });
                        console.log('Dohvaćeni podaci za edit:', result.data); // Log za podatke
                    } else {
                        console.error('Dokument s ID nije pronađen.');
                        history.push('/slider');
                    }
                })
                .catch((error) => {
                    console.error('Greška prilikom dohvaćanja:', error);
                })
                .finally(() => setLoading(false));
        } else {
            alert('ID nije pronađen.');
            history.push('/slider');
        }
    }, [history]);

    const handleSaveButton = () => {
        if (inputs.order_number) {
            setShowAddRow(false);
    
            const newId = (rows.length > 0 
                ? Math.max.apply(Math, rows.map((o) => parseInt(o.id))) + 1 
                : 0
            ).toString();
    
            addToCollection('slider_items', newId, {
                id: newId,
                index: inputs.index || '',
                image_url: inputs.image_url || '',
                title: inputs.title || '',
                link: inputs.link || '',
                subtitle: inputs.subtitle || '',
                order_number: inputs.order_number || '',
                active: inputs.active || false,
            }).then(() => {
                console.log('Učitan slider:', inputs);
                history.push('/slider'); // Prebacuje nazad na listu slajdera
            }).catch((error) => {
                console.error('Greška prilikom čuvanja slidera:', error);
            });
    
            setInputs({
                index: '',
                image_url: '',
                title: '',
                link: '',
                subtitle: '',
                order_number: '',
                active: false,
            });
        } else {
            alert('Redni broj je obavezno polje.');
        }
    };
    
    
    
    return (
        <Container>
        {loading ? (
            <Loader />
        ) : (
            <>
                <StyledH1>Redni broj:</StyledH1>
                <StyledInput
                    type="number"
                    value={inputs.order_number}
                    onChange={(e) => setInputs({ ...inputs, order_number: e.target.value })}
                />

                <StyledH1>Naslov:</StyledH1>
                <StyledInput
                    type="text"
                    value={inputs.title}
                    onChange={(e) => setInputs({ ...inputs, title: e.target.value })}
                />

                <StyledH1>Podnaslov:</StyledH1>
                <StyledInput
                    type="text"
                    value={inputs.subtitle}
                    onChange={(e) => setInputs({ ...inputs, subtitle: e.target.value })}
                />

                <StyledH1>Link:</StyledH1>
                <StyledInput
                    type="text"
                    value={inputs.link}
                    onChange={(e) => setInputs({ ...inputs, link: e.target.value })}
                />

                <StyledH1>Slika (URL):</StyledH1>
                <StyledInput
                    type="text"
                    value={inputs.image_url}
                    onChange={(e) => setInputs({ ...inputs, image_url: e.target.value })}
                />

                <StyledH1>Aktivan:</StyledH1>
                <label>
                    <StyledCheckbox
                        type="checkbox"
                        checked={inputs.active}
                        onChange={(e) => setInputs({ ...inputs, active: e.target.checked })}
                    />
                    Aktiviraj
                </label>

                <AddButton onClick={handleSaveButton}>Sačuvaj</AddButton>
            </>
        )}
    </Container>
    );
};

export default EditSlider;
