import React, { useState, useEffect } from 'react';
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style';
import { AddButton, UpdateButton, DeleteButton } from '../components/tables/Buttons';
import { getAllCollection } from '../services/database';

const Slider = ({ history }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getAllCollection('slider_items').then(result => {
            if (result.data) {
                setRows(result.data);
                console.log('Svi podaci iz baze (sa ID):', result.data);
            }
        });
    }, []);

    const handleEdit = (primaryId) => {
        if (!primaryId) {
            console.error('ID je undefined! Provjeri bazu i mapiranje podataka.');
            return;
        }
    
        console.log('Prosljeđujem ID:', primaryId.toString()); // Konvertuj u string
        history.push('/edit-slider', { id: primaryId.toString() }); // Osiguraj string
    };
    

    const handleDelete = (rowId) => {
        console.log('Brišem ID:', rowId);
        // Implementacija brisanja...
    };

    return (
        <Container>
            <PageTitle>Slider</PageTitle>
            <QuestionsContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Naslov</th>
                            <th>Akcije</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((slide) => (
                            <tr key={slide.id}>
                                <td>{slide.id}</td>
                                <td>{slide.title || 'Bez naslova'}</td>
                                <td>
                                    <UpdateButton onClick={() => handleEdit(slide.id)}>
                                        <i className="fas fa-edit"></i> Edit
                                    </UpdateButton>
                                    <DeleteButton onClick={() => handleDelete(slide.id)}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                    </DeleteButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </QuestionsContainer>
        </Container>
    );
};

export default Slider;
