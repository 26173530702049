import React, { useEffect, useState } from 'react';
import TextInput from '../components/elements/TextInput';
//Styled and native components
import { Container, Loader } from '../components/tables/Style'
import { AddButton } from '../components/tables/Buttons'


//Modules
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
import '../style/editorStyle.css';

//Hooks
import { withRouter } from 'react-router-dom';

//Services
import { addToCollection, queryCollectionByDocId } from '../services/database'

import DatePicker from "react-datepicker";
const EditPackageSubscriptions = ({ history }) => {
    //State
    const [inputs, setInputs] = useState({
        user: '',
        company: '',
        description: '',
        email: '',
        address: '',
        city: '',
        phone: '',
        zip: '',
        invoiceId: ''
    });
    const [selectedOptionPackage, setSelectedOptionPackage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState('default');
    const [packageOptions, setPackageOptions] = useState(['Pretplatnički paket: BASIC','Pretplatnički paket: BETTER VALUE','Pretplatnički paket: PREMIUM','Pretplatnički paket: REVICON CLUB']);
    const [startDate, setStartDate] = useState(new Date());
    const [paidDate, setPaidDate] = useState(new Date());
    const [selectedYear, setSelectedYear] = useState('default');
    const [isPaid, setIsPaid] = useState(false);
    const yearOptions = ['2024', '2025', '2026'];

    const handleSelectSeminarRegistration = (e) => {
        setSelectedPackage(e.target.value);
        if(e.target.value === 'default')
            setSelectedOptionPackage(false);
        else
            setSelectedOptionPackage(true);
    }

    //Effect
    useEffect(() => {
        queryCollectionByDocId('package_subscriptions', history.location.state.id.toString()) 
            .then(async result => {
                setInputs({ ...result.data});
                setSelectedOptionPackage(true);
                setSelectedPackage(result.data.package_name);
                setSelectedYear(result.data.year);
                setIsPaid(result.data.paid);

                result.data.date ? setStartDate(new Date(result.data.date)) : setStartDate("");
                result.data.paid_date ? setPaidDate(new Date(result.data.paid_date)) : setPaidDate("");
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Functions
    const handleSubmit = () => {
        console.log("Mujo id is ", history.location.state.id)
        console.log("Mujo data is ", {
            ...inputs,
            date: startDate.toString(),
            paid_date: paidDate.toString()
        })
        addToCollection('package_subscriptions', (history.location.state.id).toString(),
        {
            ...inputs,
            date: startDate.toString(),
            paid_date: paidDate.toString(),
            paid: isPaid,
            year: selectedYear,
            package_name: selectedPackage
        });
        history.push('pretplate');
    }
    const handleCancle = () => {
        history.push('pretplate');
    }

    return (
        <Container>
                <TextInput 
                    value={inputs.user??""}
                    onChange={text => setInputs({ ...inputs, user: text })}
                    placeholder={'ime učesnika'}
                    label={'Ime i prezime:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.company??""}
                    onChange={text => setInputs({ ...inputs, company: text })}
                    placeholder={'firma'}
                    label={'Firma:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.email??""}
                    onChange={text => setInputs({ ...inputs, email: text })}
                    placeholder={'email'}
                    label={'Email:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.address??""}
                    onChange={text => setInputs({ ...inputs, address: text })}
                    placeholder={'adresa'}
                    label={'Adresa:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.city??""}
                    onChange={text => setInputs({ ...inputs, city: text })}
                    placeholder={'grad'}
                    label={'Grad:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.zip??""}
                    onChange={text => setInputs({ ...inputs, zip: text })}
                    placeholder={'zip'}
                    label={'Zip:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.phone??""}
                    onChange={text => setInputs({ ...inputs, phone: text })}
                    placeholder={'telefon'}
                    label={'Telefon:'}
                    lableStyle={{ color: '#fff' }}
                />
                <TextInput 
                    value={inputs.description}
                    onChange={text => setInputs({ ...inputs, description: text })}
                    placeholder={'opis'}
                    label={'Proizvoljni opis:'}
                    lableStyle={{ color: '#fff' }}
                />

            <div style={{ height: 50 }} />

            <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedYear??"default"} onChange={e => setSelectedYear(e.target.value)}>
                    <option value={'default'}>Odaberite godinu:</option>
                    {yearOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                    }
                </select>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px' }}>
                    <p style={{ color: '#fff' }}>Odaberite datum prijave: </p>
                    <DatePicker  selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <p style={{ color: '#fff' }}>Da li je pretplata plaćena: </p>
                    <input type="checkbox" value={isPaid} checked={isPaid} onChange={(e) => setIsPaid(e.currentTarget.checked)} />  
                <div>

                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px', visibility: !isPaid? 'hidden' : '' }} >
                    <p style={{ color: '#fff' }}>Odaberite datum plaćanja: </p>
                    <DatePicker  selected={paidDate} onChange={(date) => setPaidDate(date)} />
                    <TextInput
                    value={inputs.invoiceId??""}
                    onChange={text => setInputs({ ...inputs, invoiceId: text })}
                    placeholder={'predračun'}
                    label={'Predračun:'}
                    lableStyle={{ color: '#fff' }}
                />
                </div>
                <h2 style={{ color: '#fff' }}>
                        Paket: 
                    </h2>
                    <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedPackage??"default"} onChange={handleSelectSeminarRegistration}>
                        <option value={'default'}>Izaberite paket</option>
                        {packageOptions.length > 0
                            ? packageOptions.map((item, index) => (
                                <option key={`seminar_option_${index}`} value={item}>{item}</option>
                            ))
                            : false
                        }
                    </select>
            <p/>
            <AddButton onClick={() => handleSubmit()}>
                <i className="fas fa-plus-circle"></i>
                <h1>Sačuvaj</h1>
            </AddButton>
        </Container >
    )
}

export default withRouter(EditPackageSubscriptions);
