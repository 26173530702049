import TextInput from '../components/elements/TextInput';
import {
    Backdrop,
    ModalContainer,
    TitleContainer,
    ContentContainer,
    ExitButton
} from '../screens/Styles/EducationRegistration';
import React, { useState, useEffect } from 'react';
import { getAllCollection, addToCollection, deleteFromCollection, editDocument, getAllCollectionWithDocIdAndValuesInCondition, queryCollection, getDocumnetByDocId, addToCollectionAutoGenerateId } from '../services/database';
import DatePicker from "react-datepicker";

import { AddButton, UpdateButton, DeleteButton, CancleButton } from '../components/tables/Buttons'
export default function AddPackageSubscription({showModal, handleShowModal}) {

    
    const [packageInputs, setPackageInputs] = useState({
        user: '',
        company: '',
        package_name: '',
        description: '',
        email: '',
        address: '',
        city: '',
        phone: '',
        date: '',
        zip: '',
        paid: false,
        paid_date: new Date(),
        invoiceId: ''
    });


    const [selectedOptionPackage, setSelectedOptionPackage] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState('default');
    const [packageOptions, setPackageOptions] = useState(['Pretplatnički paket: BASIC','Pretplatnički paket: BETTER VALUE','Pretplatnički paket: PREMIUM','Pretplatnički paket: REVICON CLUB']);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedYear, setSelectedYear] = useState('default');
    const [isPaid, setIsPaid] = useState(false);
    const yearOptions = ['2024', '2025', '2026'];


    const handleSelectSeminarRegistration = (e) => {
        setSelectedPackage(e.target.value);
        setPackageInputs({ ...packageInputs, package_name: e.target.value });
        if(e.target.value === 'default')
            setSelectedOptionPackage(false);
        else
            setSelectedOptionPackage(true);
    }
    const handleNewSubscriptionSubmit = () => {

        addToCollectionAutoGenerateId(
            'package_subscriptions',
            {
                user:  packageInputs.user,
                date: startDate.toString(),
                package_name: packageInputs.package_name,
                city: packageInputs.city,
                address: packageInputs.address,
                phone: packageInputs.phone,
                company: packageInputs.company,
                email: packageInputs.email,
                zip: packageInputs.zip,
                year: selectedYear,
                paid: isPaid,
                paid_date: isPaid? packageInputs.paid_date.toString() : '',
                invoiceId: isPaid? packageInputs.invoiceId : ''
            }
        ).then(res => {
            console.log('Saved insert');
            setPackageInputs({...packageInputs,  user: '', company: '', email: '', address: '', city: '', phone: '', date: new Date(), zip: '', description:'', paid_date:  new Date(), invoiceId:''});
            setIsPaid((p) => false);
            setSelectedPackage('default');
            setSelectedOptionPackage(false);
            setStartDate(new Date());
            setSelectedYear('default');
        });
    }
    
    return (
        <Backdrop show={showModal}>
            <ModalContainer>
                {
                 <ExitButton
                            onClick={() => handleShowModal()}
                        >
                            <img
                                alt={'Zatvori'}
                                src={require('../images/exit.png')}
                            />
                        </ExitButton>
                }
                <TitleContainer>
                    <h2>
                        Odaberite paket
                    </h2>
                    <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedPackage} onChange={handleSelectSeminarRegistration}>
                    <option value={'default'}>Izaberite paket</option>
                    {packageOptions.length > 0
                        ? packageOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                        : false
                    }
                    </select>
                    <h6>
                        Registrujte prijavuu na paket
                    </h6>
                </TitleContainer>

                <ContentContainer show={selectedOptionPackage}>
                <TextInput
                    value={packageInputs.user}
                    onChange={text => setPackageInputs({ ...packageInputs, user: text })}
                    placeholder={'ime učesnika'}
                    label={'Ime i prezime:'}
                />
                <TextInput
                    value={packageInputs.company}
                    onChange={text => setPackageInputs({ ...packageInputs, company: text })}
                    placeholder={'firma'}
                    label={'Firma:'}
                />
                <TextInput
                    value={packageInputs.email}
                    onChange={text => setPackageInputs({ ...packageInputs, email: text })}
                    placeholder={'email'}
                    label={'Email:'}
                />
                <TextInput
                    value={packageInputs.address}
                    onChange={text => setPackageInputs({ ...packageInputs, address: text })}
                    placeholder={'adresa'}
                    label={'Adresa:'}
                />
                <TextInput
                    value={packageInputs.city}
                    onChange={text => setPackageInputs({ ...packageInputs, city: text })}
                    placeholder={'grad'}
                    label={'Grad:'}
                />
                <TextInput
                    value={packageInputs.zip}
                    onChange={text => setPackageInputs({ ...packageInputs, zip: text })}
                    placeholder={'zip'}
                    label={'Zip:'}
                />
                <TextInput
                    value={packageInputs.phone}
                    onChange={text => setPackageInputs({ ...packageInputs, phone: text })}
                    placeholder={'telefon'}
                    label={'Telefon:'}
                />
                <TextInput
                    value={packageInputs.description}
                    onChange={text => setPackageInputs({ ...packageInputs, description: text })}
                    placeholder={'opis'}
                    label={'Proizvoljni opis:'}
                />
                <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
                    <option value={'default'}>Odaberite godinu:</option>
                    {yearOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                    }
                </select>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px' }}>
                    <p>Odaberite datum prijave: </p>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <p>Da li je pretplata plaćena: </p>
                    <input type="checkbox" value={isPaid} checked={isPaid} onChange={(e) => setIsPaid(e.currentTarget.checked)} />  
                <div>

                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px', visibility: !isPaid? 'hidden' : '' }} >
                    <p>Odaberite datum plaćanja: </p>
                    <DatePicker selected={packageInputs.paid_date} onChange={(date) => setPackageInputs({ ...packageInputs, paid_date: date })} />
                    <TextInput
                    value={packageInputs.invoiceId}
                    onChange={text => setPackageInputs({ ...packageInputs, invoiceId: text })}
                    placeholder={'predračun'}
                    label={'Predračun:'}
                />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '5px', marginBottom: '10px' }}>
                    <AddButton onClick={() => handleNewSubscriptionSubmit()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                </div>
                </ContentContainer>
            </ModalContainer>
            
            </Backdrop>
    )

}