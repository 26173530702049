import React, { useState, useEffect, useRef } from 'react';
import TextInput from '../components/elements/TextInput';

import InvoiceTable from '../components/invoices/InvoiceTable';
import { Container, QuestionsContainer, PageTitle, Table, Loader } from '../components/tables/Style'
import { addToCollectionAutoGenerateId } from '../services/database';
import { AddButton, SendButton } from '../components/tables/Buttons'
import { InputArea } from './Styles/Inputs';
import { useAuth } from '../contexts/Auth';
import {
    Backdrop,
    ModalContainer,
    TitleContainer,
    ContentContainer,
    ExitButton
} from '../screens/Styles/EducationRegistration';

import { getAllCollection, addToCollection, deleteFromCollection } from '../services/database';

import { withRouter } from 'react-router-dom';


const Invoices = ({ history }) => {


    const auth = useAuth();

    const [email, setEmail] = useState('');
    const fileInputRef = useRef(null);
    const [invoiceArray, setInvoiceArray] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleFileUpload = () => {
        // Accessing the file from the input element
        const file = fileInputRef.current.files[0];
        // Do something with the file, e.g., send it to a server
        //console.log('Uploaded file:', file);
    };
    const removeElementByIndex = (indexToRemove, array) => {
        const newArray = [...array];

        if (indexToRemove >= 0 && indexToRemove < newArray.length) {
            newArray.splice(indexToRemove, 1);
        } else {
            console.error(`Index ${indexToRemove} is out of bounds for array length ${newArray.length}`);
        }

        return newArray;
    };

    const handleRemoveButton = (index) => {
        const updatedArray = removeElementByIndex(index, invoiceArray);
        setInvoiceArray(updatedArray);
    };

    const handleAddButton = (e) => {
        setInvoiceArray([...invoiceArray, { email: email, fileName: fileInputRef.current.files[0].name, data: fileInputRef.current.files[0] }])
    }

    const handleSendButton = (e) => {
        setLoading(true);
        let numberOfEmails = invoiceArray.length
        let sentEmails = 0
        invoiceArray.forEach(element => {
            try {
                const formData = new FormData();
                formData.append('to', element.email);
                formData.append('subject', "Faktura/Invoice ");
                if (fileInputRef) {
                    formData.append('file', element.data);
                }

                let date = new Date()
                let fileName = element.data.name

                fetch('https://us-central1-revicon-1dadd.cloudfunctions.net/sendInvoiceEmail', {
                    method: 'POST',
                    body: formData
                }).then(res => {
                    if (res.status === 200) {
                        addToCollectionAutoGenerateId(
                            'invoice_logs',
                            {
                                admin: auth.user.email,
                                date: date.toString(),
                                message: 'Admin ' + auth.user.email + ' je poslao fakturu na datum: ' + date.toLocaleDateString() + ", email primaoca: " + element.email + ", naziv fajla: " + fileName,
                                user: element.email,
                                fileName: fileName
                            }
                        ).then(res => {
                            console.log('Saved insert');
                        });
                        console.log("Email uspjesno poslat");
                        sentEmails++
                        if(sentEmails == numberOfEmails){                                            
                            setInvoiceArray([]);
                            setLoading(false);
                            alert('Email uspjesno poslat');
                        }
                    } else {
                        setLoading(false)
                        alert('Doslo je do problema');
                    }
                }).catch((e) => {
                    console.log(e)
                    setLoading(false)
                    alert('Doslo je do problema');
                })
            } catch (error) {
                console.error('Error sending email:', error);
                setLoading(false)
                alert('Doslo je do problema');
            }
        });
    };

    return (
        <div style={{ width: '100%' }}>
            <h2 style={{ color: '#f0f0f0' }}>Fakture</h2>

            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Loader />
                </div> :
                <div style={{ backgroundColor: '#f2eee1' }}>

                    <TitleContainer>
                        <div style={{ backgroundColor: '#f2eee1', width: '80%', display: 'flex', justifyContent: 'center' }}>
                            <TextInput
                                value={email}
                                onChange={text => setEmail(text)}
                                placeholder={'email'}
                                label={'Email primaoca fakture'}
                            />
                        </div>
                        <div style={{ marginTop: '25px' }}>
                            <label htmlFor="fileInput">Upload File:  </label>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                            />
                        </div>
                        <AddButton style={{ marginTop: '25px', marginBottom: '25px' }} onClick={() => handleAddButton()}>
                            <i className="fas fa-plus-circle"></i>
                            <h1>Dodaj</h1>
                        </AddButton>

                        <InvoiceTable invoiceArray={invoiceArray} handleRemoveButton={handleRemoveButton} />
                        <SendButton style={{ marginTop: '25px', marginBottom: '25px' }} onClick={() => handleSendButton()}>
                            <i className="fas fa-paper-plane"></i>
                            <h1>Pošalji</h1>
                        </SendButton>
                    </TitleContainer>
                </div>}
        </div>
    );
};

export default withRouter(Invoices);