import styled from 'styled-components';

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 4px;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : '#27C24C'};
    width: 100px;
    min-height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);

    h1 {
        margin: 0;
        color: #f1f1f1;
        font-weight: bold;
        font-size: 16px;
    }

    i {
        color: #f1f1f1;
        font-size: 18px;
    }
`

export const UpdateButton = styled.div`
    cursor: pointer;
    border-radius: 4px;
    background-color: #19A9D5;
    min-width: 50px;
    min-height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);

    i {
        color: #f1f1f1;
        font-size: 18px;
    }
`

export const DeleteButton = styled.div`
    cursor: pointer;
    border-radius: 4px;
    background-color: #EE3939;
    width: 50px;
    min-height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);

    i {
        color: #f1f1f1;
        font-size: 18px;
    }
`
export const CancleButton = styled.div`
cursor: pointer;
    border-radius: 4px;
    background-color: #EE3939;
    width: 100px;
    min-height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);

    h1 {
        margin: 0;
        color: #f1f1f1;
        font-weight: bold;
        font-size: 16px;
    }


i {
    color: #f1f1f1;
    font-size: 18px;
}
`
export const SendButton = styled.div`
cursor: pointer;
    border-radius: 4px;
    background-color: #E8BA25;
    width: 100px;
    min-height: 35px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);
    box-shadow: 3px 4px 2px -3px rgba(0,0,0,0.75);

    h1 {
        margin: 0;
        color: #f1f1f1;
        font-weight: bold;
        font-size: 16px;
    }


i {
    color: #f1f1f1;
    font-size: 18px;
}
`