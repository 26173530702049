import firebase from '../config/firebase';

//READ WHOLE COLLECTION
export async function getAllCollection(collection) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).get();
        const dataResult = data.docs.map(doc => ({
            id: doc.id, // Dohvaćanje ID-a dokumenta
            ...doc.data() // Ostali podaci
        }));

        return { data: dataResult };
    } catch (error) {
        console.log(error);
    }
}


//READ WHOLE COLLECTION WITH DOCUMENT ID
export async function getAllCollectionWithDocId(collection) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).get();
        const dataResult = data.docs.map(doc => ({id: doc.id, data: doc.data()}))

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
//READ WHOLE COLLECTION WITH DOCUMENT ID
export function getAllCollectionWithDocIdAndValuesInCondition(collection, year, packageSub, callback) {
    const db = firebase.firestore();
    let query = db.collection(collection);

    if (year !== '') {
        query = query.where('year', '==', year);
    }

    // Package-based filtering
    if (packageSub === 'basic') {
        query = query.where('package_name', 'in', ['BASIC', 'Pretplatnički paket: BASIC']);
    } else if (packageSub === 'bv') {
        query = query.where('package_name', 'in', ['BETTER VALUE', 'Pretplatnički paket: BETTER VALUE', 'Pretplatnički paket: BETTER VALUE']);
    } else if (packageSub === 'premium') {
        query = query.where('package_name', 'in', ['PREMIUM', 'Pretplatnički paket: PREMIUM']);
    } else if (packageSub === 'rc') {
        query = query.where('package_name', 'in', ['REVICON CLUB', 'Pretplatnički paket: REVICON CLUB']);
    }

    // Real-time listener
    const unsubscribe = query.onSnapshot(snapshot => {
        const dataResult = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        callback(dataResult);  // Execute callback with the updated data
    }, error => console.log(error));

    return unsubscribe;  // Return unsubscribe function for cleanup
}
//READ WHOLE COLLECTION WITH DOCUMENT ID AND CRITERIA
export function getAllCollectionWithDocIdAndCriteria(collection, queryField, comparison, queryCriteria, callback) {
    const db = firebase.firestore();
    const query = db.collection(collection).where(queryField, comparison, queryCriteria);

    // Listen for real-time updates
    const unsubscribe = query.onSnapshot(snapshot => {
        const dataResult = snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
        callback(dataResult);
    }, error => console.log(error));

    // Return the unsubscribe function for cleanup
    return unsubscribe;
}
//QUERY COLLECTION WITH DESIRED CRITERIA
export async function queryCollection(collection, queryField, comparission, queryCriteria) {
    try {
        const db = firebase.firestore();
        const data = await db.collection(collection).where(queryField, comparission, queryCriteria).get();
        const dataResult = data.docs.map(doc => doc.data())

        return new Promise((resolve) => {
            if (data) {
                resolve({
                    data: dataResult
                })
            }
        })
    }
    catch (error) {
        console.log(error)
    }
}
export async function queryCollectionByDocId(collection, id) {
    try {
        const db = firebase.firestore();
        const doc = await db.collection(collection).doc(id).get();
        return { data: doc.data() };
    } catch (error) {
        console.error('Greška u dohvaćanju dokumenta:', error);
        return { data: null };
    }
}


//ADD DOC TO COLLECTION
export async function addToCollection(collection, doc, data) {
    try {
        const db = firebase.firestore();
        db.collection(collection).doc(doc).set(data);
    }
    catch (error) {
        console.log(error)
    }
}

//ADD DOC TO COLLECTION
export async function addToCollectionAutoGenerateId(collection, data) {
    try {
        const db = firebase.firestore();
        db.collection(collection).add(data);
    }
    catch (error) {
        console.log(error)
    }
}

//DELETE DOC FROM COLLECTION
export async function deleteFromCollection(collection, doc) {
    try {
        const db = firebase.firestore();
        await db.collection(collection).doc(doc.toString()).delete();
    }
    catch (error) {
        console.log(error)
    }
}

//EDIT DOCUMENT
export async function editDocument(collection, doc, field, value) {
    try {
        const db = firebase.firestore();
        await db.collection(collection).doc(doc.toString()).update({ [field]: value });
    }
    catch (error) {
        console.log(error)
    }
}

//Revoke access permissions
export async function revokePermissions(doc) {
    try {
        const db = firebase.firestore();
        db.collection('users').doc(doc.toString()).update(
            {
                'baza_znanja': false,
                'biblioteka': false,
                'porezni': false
            }
        );
    }
    catch (error) {
        console.log(error)
    }
}
