import TextInput from '../elements/TextInput';


import { Container, QuestionsContainer, PageTitle, Table } from '../tables/Style'
import {
    Backdrop,
    ModalContainer,
    TitleContainer,
    ContentContainer,
    ExitButton
} from '../../screens/Styles/EducationRegistration';
import React, { useState, useEffect } from 'react';
//import { getAllCollection, addToCollection, deleteFromCollection, editDocument, getAllCollectionWithDocIdAndValuesInCondition, queryCollection, getDocumnetByDocId, addToCollectionAutoGenerateId } from '../services/database';
import DatePicker from "react-datepicker";

import { AddButton, UpdateButton, DeleteButton, CancleButton } from '../tables/Buttons'
export default function InvocieTable({ invoiceArray, handleRemoveButton }) {


    const [selectedOptionPackage, setSelectedOptionPackage] = useState(false);

    const handleNewSubscriptionSubmit = () => {
        console.log('Mujo delete',)
    }

    return (
        <Table style={{ marginBottom: 20 }} key={`seminar_data_${0}`}>
            <tbody>
                <tr style={{ maxWidth: 300 }}>
                    <th style={{ maxWidth: '10%', color: 'black' }}>Email korisnika:</th>
                    <th style={{ maxWidth: '10%', color: 'black' }}>Fajl: </th>
                    <th style={{ maxWidth: '10%', color: 'black' }}></th>

                </tr>
                {invoiceArray.length > 0
                    ? invoiceArray.map((item, index) => (
                        <tr key={'item_id_' + index}>
                            <td style={{ maxwith: '30%' }}>
                                <TextInput
                                    value={item.email}
                                    disaled={true}
                                /></td>
                            <td style={{ maxwith: '30%' }}>
                                <TextInput
                                    value={item.fileName}
                                    disaled={true}
                                /></td>
                            <td><div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '10px', justifyContent: 'center' }}>
                                <DeleteButton style={{ width: '50%' }} onClick={() => handleRemoveButton(index)}>
                                    <i className="fas fa-trash-alt"></i>
                                </DeleteButton>
                            </div></td>
                        </tr>

                    ))
                    : false
                }</tbody>
        </Table>
    )

}