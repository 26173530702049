//React
import React, { useState, useEffect, useRef  } from 'react';
import { useAuth } from '../contexts/Auth';
//Components
import { Container, QuestionsContainer, PageTitle, Table } from '../components/tables/Style'
import TextInput from '../components/elements/TextInput';

//Services
import { queryCollection, getAllCollectionWithDocIdAndCriteria, editDocument , addToCollectionAutoGenerateId } from '../services/database';

//Modules
import CsvDownload from 'react-json-to-csv'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";

//input area
import { InputArea } from './Styles/Inputs';
import {
    Backdrop,
    ModalContainer,
    TitleContainer,
    ContentContainer,
    ExitButton
} from './Styles/EducationRegistration';
import { UpdateButton, AddButton, CancleButton } from '../components/tables/Buttons';


const loggedUsers = new Set(); // Globalni set za praćenje logovanih korisnika
let loggedMessage = false; // Flag za kontrolu ispisivanja poruka

const checkAccess = (user) => {
    if (!user || !user.email) {
        return <p>Nemate pravo pristupa ovoj stranici.</p>;
    }

    if (typeof user.isAdmin === "undefined" || typeof user.isRacunovodstvo === "undefined") {
        return <p>Korisnički podaci se učitavaju...</p>;
    }

    // Provjera prava pristupa
    if (user.isAdmin && user.isRacunovodstvo) {
        if (!loggedUsers.has(user.email)) {
            if (!loggedMessage) {
                console.log(`Pristup dozvoljen korisniku: ${user.email}`);
                loggedMessage = true; // Flag postavljen na true
            }
            loggedUsers.add(user.email); // Dodaj korisnika u log
        }
        return null; // Pristup dozvoljen
    }

    if (!loggedUsers.has(user.email)) {
        if (!loggedMessage) {
            console.log(`Pristup odbijen korisniku: ${user.email}. isAdmin: ${user.isAdmin}, isRacunovodstvo: ${user.isRacunovodstvo}`);
            loggedMessage = true; // Flag postavljen na true
        }
        loggedUsers.add(user.email); // Dodaj korisnika u log
    }
    return <p>Pristup ogranicen</p>;
};




const RegisteredUsers = () => {
    //Initialization
    const auth = useAuth();

    //State
    const [seminarOptions, setSeminarOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOption2, setSelectedOption2] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('default');
    const [selectedSort, setSelectedSort] = useState('default');
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [rowsOriginal, setRowsOriginal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalInvoice, setShowModalInvoice] = useState(false);
    const [optionSelected, setOptionSelected] = useState(false);
    const [selectedRegistration, setSelectedRegistration] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [inputs, setInputs] = useState({
        description: '',
    });

    const fileInputRef = useRef(null);

    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [invoice, setInvoice] = useState('');
    const [registrationInputs, setRegistrationInputs] = useState({
        user: '',
        company: '',
        seminar_name: '',
        payment: '',
        email: '',
        address: '',
        city: '',
        phone: '',
        date: '',
        termin: '',
        zip: '',
    });
    const [editPaid, setEditPaid] = useState(false)
    const [editPayment_option, setEditPayment_option] = useState(false)
    const [updateRowId, setUpdateRowId] = useState('')
    
    //Effect
    useEffect(() => {
        let isMounted = true; // Provjera da li je komponenta montirana
    
        const fetchUserData = async () => {
            try {
                console.log(`Početak dohvaćanja podataka za korisnika: ${auth.user.email}`);
                const userData = await queryCollection("users", "email", "==", auth.user.email);
    
                if (isMounted) {
                    if (userData?.data?.length > 0) {
                        const user = userData.data[0];
                        console.log("Dohvaćeni podaci iz baze:", user);
    
                        auth.setUser((prevUser) => ({
                            ...prevUser,
                            isAdmin: user.isAdmin || false,
                            isRacunovodstvo: user.isRacunovodstvo || false,
                        }));
    
                        console.log("Korisnički podaci uspješno ažurirani:", user);
                    } else {
                        console.warn(`Korisnik sa emailom ${auth.user.email} nije pronađen u bazi.`);
                    }
                }
            } catch (error) {
                console.error("Greška prilikom dohvaćanja korisničkih podataka:", error);
            }
        };
    
        if (auth.user?.email) {
            fetchUserData();
        }
    
        return () => {
            isMounted = false; // Postavi oznaku da je komponenta demontirana
        };
    }, [auth.user?.email]);
    
    
    
    useEffect(() => {
        queryCollection('educations', 'ongoing', '==', true)
            .then(res1 => {
                queryCollection('educations', 'ongoing', '==', 'true')
                    .then(res => {
                        setSeminarOptions(res1.data.concat(res.data));
                    })
                    .catch(e => console.log(e))
            })
            .catch(e => console.log(e))
    }, []);
    useEffect(() => {
        if (selectedOption) {
            const unsubscribe = getAllCollectionWithDocIdAndCriteria(
                'user_subscriptions',
                'seminar_name',
                '==',
                selectedOption,
                (dataResult) => {
                    setRowsOriginal(dataResult); // Postavi originalne podatke
                    
                    let updatedRows = [...dataResult]; // Kreiraj kopiju podataka za manipulaciju
    
                    // Sortiranje podataka ako je izabrana opcija za sortiranje
                    if (selectedSort === 'datum') {
                        updatedRows.sort((a, b) => {
                            const dateA = new Date(a.data.date.replace(/(.*)GMT.*/, '$1'));
                            const dateB = new Date(b.data.date.replace(/(.*)GMT.*/, '$1'));
                            return dateA - dateB;
                        });
                    } else if (selectedSort === 'ime') {
                        updatedRows.sort((a, b) => a.data.user.localeCompare(b.data.user));
                    }
    
                    // Filtriranje na osnovu izabranog filtera
                    if (selectedFilter !== 'default') {
                        updatedRows = updatedRows.filter(row => {
                            switch (selectedFilter) {
                                case 'placeno':
                                    return row.data.status === 'plaćeno';
                                case 'neplaceno':
                                    // Filtriraj samo redove gde je status prazan
                                    return !row.data.status || row.data.status.trim() === '';
                                case 'otkazano':
                                    return row.data.status === 'otkazano';
                                case 'ugovor/odluka':
                                    return row.data.status === 'ugovor/odluka';
                                case 'snimak':
                                    return row.data.status === 'snimak';
                                case 'fakturisano':
                                    return row.data.status === 'fakturisano';
                                default:
                                    return true;
                            }
                        });
                    }
    
                    setRows2(updatedRows); // Postavi filtrirane podatke
    
                    // Resetuj stanja za editovanje
                    setEditPaid(false);
                    setUpdateRowId('');
                    setInputs({ ...inputs, description: '' });
                }
            );
    
            return () => unsubscribe(); // Čišćenje efekta
        }
    }, [selectedOption, selectedFilter, selectedSort]); // Dodaj zavisnosti za praćenje promena
        // Provjera pristupa nakon inicijalizacije hookova
        const accessMessage = checkAccess(auth.user);
        if (accessMessage) {
            return <Container>{accessMessage}</Container>;
        }
    

    const handleUpdate = (id) => {
        setUpdateRowId(id);
        setEditPaid(true);
        setEditPayment_option(true);
        
        // history.push('/edit-educations', {
        //     id: id
        // });
    }
    //Functions
  // Funkcija za generisanje imena fajla sa odgovarajućom ekstenzijom
  const generateFileName = (extension = "csv") => {
    const date = new Date();
    return `export_${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}_${date.getHours()}-${date.getMinutes()}.${extension}`;
};

const downloadExcel = () => {
    if (!rows2 || rows2.length === 0) {
        alert("Nema podataka za izvoz.");
        return;
    }

    // Definišemo zaglavlja tabele
    const headers = [
        "Kompanija",
        "Grad",
        "Opcija plaćanja",
        "Ime i prezime",
        "Email",
        "Telefon",
        "Datum prijave",
        "Termin",
        "Adresa",
        "Predračun",
        "Poštanski broj",
        "Status",
        "Opis"
    ];
    // Pripremamo podatke za Excel
    const data = rows2.map(row => ({
        Kompanija: row.data.company || "",
        Grad: row.data.city || "",
        "Opcija plaćanja": row.data.payment_option || "",
        "Ime i prezime": row.data.user || "",
        Email: row.data.email || "",
        Telefon: row.data.phone || "",
        "Datum prijave": row.data.date || "",
        Termin: row.data.termin || "",
        Adresa: row.data.address || "",
        Predračun: row.data.invoiceId || "",
        "Poštanski broj": row.data.zip || "",
        Status: row.data.status || "",
        Opis: row.data.description || ""
    }));

    // Kreiramo worksheet i workbook
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Podaci");

    const fileName = generateFileName("xlsx");
    XLSX.writeFile(workbook, fileName);
};

const generateCSVContent = () => {
    if (!rows2.length) {
        alert("Nema podataka za izvoz.");
        return null;
    }

    const headers = [
        "Kompanija", 
        "Grad", 
        "Opcija plaćanja", 
        "Ime i prezime", 
        "Email", 
        "Telefon", 
        "Datum prijave", 
        "Termin", 
        "Adresa", 
        "Predračun", 
        "Poštanski broj", 
        "Status", 
        "Opis"
    ];

    // Generisanje zaglavlja
    const csvRows = [headers.join(",")];

    // Generisanje redova
    rows2.forEach(row => {
        const rowData = [
            row.data.company || "",
            row.data.city || "",
            row.data.payment_option || "",
            row.data.user || "",
            row.data.email || "",
            row.data.phone || "",
            row.data.date || "",
            row.data.termin || "",
            row.data.address || "",
            row.data.invoiceId || "",
            row.data.zip || "",
            row.data.status || "",
            row.data.description || ""
        ];
        csvRows.push(rowData.join(","));
    });

    // Pretvaranje CSV niza u string
    return csvRows.join("\n");
};

const downloadCSV = () => {
    const csvContent = generateCSVContent();
    if (!csvContent) return;

    const bom = "\uFEFF";
    const blob = new Blob([bom + csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const fileName = generateFileName("csv");

    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
    
    const handleSelectSeminar = (seminar) => {
        setSelectedOption(seminar);
        setSelectedUsers([])
    }

    const handleFileUpload = () => {
        const file = fileInputRef.current.files[0];
    };

    const handleRowSelect = (id) => {
        setSelectedUsers(prevSelected => 
          prevSelected.includes(id) ? prevSelected.filter(userId => userId !== id) : [...prevSelected, id]
        );
      };

    const handleSendInvoiceButton = (e) => {
        const numberOfEmails = selectedUsers.length;
        let sentEmails = 0;

        selectedUsers.forEach(userId => {
            const user = rows2.find(row => row.id === userId);

            try {
                const formData = new FormData();
                formData.append('to', user.data.email);
                formData.append('subject', "Faktura/Invoice");
                var file = fileInputRef.current.files[0]
                if (file) {
                    formData.append('file', file);
                }

                const date = new Date();
                const fileName = file ? file.name : '';

                fetch('https://us-central1-revicon-1dadd.cloudfunctions.net/sendInvoiceEmail', {
                    method: 'POST',
                    body: formData
                }).then(res => {
                    if (res.status === 200) {
                        addToCollectionAutoGenerateId(
                            'invoice_logs',
                            {
                                admin: auth.user.email,
                                date: date.toString(),
                                message: `Admin ${auth.user.email} sent an email on ${date.toLocaleDateString()} to: ${user.data.email}, with file: ${fileName}`,
                                user: user.data.email,
                                fileName: fileName
                            }
                        ).then(() => console.log('Saved insert'));
                        editDocument('user_subscriptions', userId, 'status', 'fakturisano').then(result => {
                        });
                        sentEmails++;
                        if (sentEmails === numberOfEmails) {
                            setSelectedUsers([]);
                            alert('Emails successfully sent!');
                        }
                    } else {
                        alert('There was a problem sending the email.');
                    }
                }).catch((e) => {
                    console.log(e);
                    alert('There was a problem sending the email.');
                });
            } catch (error) {
                console.error('Error sending email:', error);
                alert('There was a problem sending the email.');
            }
        });
    };  

    const handleSelectFilter = (e) => {
        const selectedValue = e.target.value;
    
        setSelectedFilter(selectedValue); // Postavljamo trenutni filter
        setSelectedUsers([]);
        setSelectedSort('default'); // Resetujemo sortiranje
    
        // Filtriranje na osnovu vrednosti filtera
        if (selectedValue === 'default') {
            setRows2(rowsOriginal); // Prikazujemo sve podatke
        } else if (selectedValue === 'placeno') {
            setRows2(rowsOriginal.filter((i) => i.data.status === 'plaćeno'));
        } else if (selectedValue === 'neplaceno') {
            // Filtriraj redove gde je polje `status` prazno
            setRows2(
                rowsOriginal.filter(
                    (i) =>
                        !i.data.status || // Proverava da li je undefined ili null
                        i.data.status.trim() === '' // Proverava da li je prazan string
                )
            );
        } else if (selectedValue === 'otkazano') {
            setRows2(rowsOriginal.filter((i) => i.data.status === 'otkazano'));
        } else if (selectedValue === 'ugovor/odluka') {
            setRows2(rowsOriginal.filter((i) => i.data.status === 'ugovor/odluka'));
        } else if (selectedValue === 'snimak') {
            setRows2(rowsOriginal.filter((i) => i.data.status === 'snimak'));
        } else if (selectedValue === 'fakturisano') {
            setRows2(rowsOriginal.filter((i) => i.data.status === 'fakturisano'));
        }
    };
    const handleSelectSort = (e) => {
        setSelectedSort(e.target.value)
        if(e.target.value === 'datum'){
            setRows2(rows2.sort((a, b) => {
                const dateA = new Date(a.data.date.replace(/(.*)GMT.*/, '$1'));
                const dateB = new Date(b.data.date.replace(/(.*)GMT.*/, '$1'));
                return dateA - dateB;
              }))
        }else if(e.target.value === 'ime'){
            setRows2(rows2.sort((a, b) => {
                return a.data.user.localeCompare(b.data.user)
              }))
        }
    }
    const handleSelectSeminarRegistration = (e) => {
        setSelectedRegistration(e.target.value);
        setRegistrationInputs({ ...registrationInputs, seminar_name: e.target.value });
        if(e.target.value === 'default')
            setOptionSelected(false);
        else
            setOptionSelected(true);
    }

    function HandleStatus(id, statusDB, userName, email, seminarTitle) {
        const handleSelectChange = (event) => {
            const newStatusValues = event.target.value
            editDocument('user_subscriptions', id, 'status', newStatusValues).then(result => {
                addToCollectionAutoGenerateId(
                    'user_subscription_logs',
                    {
                        admin: auth.user.email,
                        date: new Date().toString(),
                        newStatus: newStatusValues,
                        previousStatus: statusDB?statusDB:'',
                        user: userName,
                        seminarName: seminarTitle
                    }
                ).then(res => {
                    console.log('Saved insert');
                });
                if(newStatusValues === 'plaćeno'){
                    const postData = {
                        userName: userName,
                        email: email,
                        seminarName: seminarTitle
                    }
                    const URL = 'https://us-central1-revicon-1dadd.cloudfunctions.net'
                    fetch(`${URL}/subscriptionPaid`, {
                        method: 'POST',
                        body: JSON.stringify(postData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        if (res.status === 200) {
                            console.log("Email uspjesno poslat");
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
            });
        };

        return (<>
            {
                <div style={{width : '130px'}}>
                    <div>
                        <select style={{ width: '100%', marginTop: 15, marginBottom: 25, height: 35 }} value={statusDB?statusDB:''} onChange={handleSelectChange}>
                            <option key='0' value={''}>Izaberite opciju</option>
                            <option key='1' value={'plaćeno'}>plaćeno</option>
                            <option key='2' value={'ugovor/odluka'}>ugovor/odluka</option>
                            <option key='3' value={'otkazano'}>otkazano</option>
                            <option key='4' value={'snimak'}>snimak</option>
                            <option key='5' value={'fakturisano'}>fakturisano</option>
                        </select>
                    </div>
                </div>
            }
        </>)
    }

    function HandleStatus2(id, statusDB, userName, email, seminarTitle) {
        const handleSelectChange2 = (event) => {
            var dataSaved = false;
            const newStatusValues = event.target.value
            editDocument('user_subscriptions', id, 'acces_data_sent', "true");
            editDocument('user_subscriptions', id, 'status', newStatusValues).then(result => {
                addToCollectionAutoGenerateId(
                    'user_subscription_logs',
                    {
                        admin: auth.user.email,
                        date: new Date().toString(),
                        newStatus: newStatusValues,
                        previousStatus: statusDB?statusDB:'',
                        user: userName,
                        userEmail: email,
                        seminarName: seminarTitle
                    }
                ).then(res => {
                    dataSaved = true;
                    console.log('Saved insert');
                });
            });
        };
        return (<>
            {
                <div style={{width : '130px'}}>
                    <div>
                        <select style={{ width: '100%', marginTop: 15, marginBottom: 25, height: 35 }} value={statusDB?statusDB:''} onChange={handleSelectChange2}>
                            <option key='0' value={''}>Izaberite opciju</option>
                            <option key='1' value={'sent'}>Posalji email</option>
                            <option key='2' value={''}>Nije plaćeno</option>
                        </select>
                    </div>
                </div>
            }
        </>)
    }

    const handleAddButton = (id) => {
            editDocument('user_subscriptions', id, 'description', inputs.description).then(result => {
            });
    }

    const handleAddButtonpayment = (id) => {
        editDocument('user_subscriptions', id, 'payment_option', inputs.payment_option).then(result => {
        });
}
    const handleNewSubscription = () => {
        setShowModal(true);
    }
    const closeModal = () => {
        setOptionSelected(true);
        setShowModal(false);
    }
    const handleSendInvoice = () => {
        setShowModalInvoice(true);
    }
    const closeModalInvoice = () => {
        setShowModalInvoice(false);
    }

    const handleNewSubscriptionSubmit = () => {
        addToCollectionAutoGenerateId(
            'user_subscriptions',
            {
                user:  registrationInputs.user,
                date: startDate.toString(),
                seminar_name: registrationInputs.seminar_name,
                payment_option: registrationInputs.payment,
                city: registrationInputs.city,
                address: registrationInputs.address,
                phone: registrationInputs.phone,
                company: registrationInputs.company,
                email: registrationInputs.email,
                termin: registrationInputs.termin,
                zip: registrationInputs.zip,
            }
        ).then(res => {
            console.log('Saved insert');
            setRegistrationInputs({...registrationInputs,  user: '', company: '', email: '', address: '', city: '', phone: '', date: new Date(), zip: '' });
            setSelectedRegistration('');
            setOptionSelected(false);
        });
    }

    function handleDescription(id, description) {
        return (<>
            { description? 
            <div style={{width : '130px'}}>
                <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div> :
                <div style={{width : '130px'}}>
                    <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, description: e.target.value });
                        }}
                        value={inputs.description}
                    />
                    </div>
                </div>
            }
        </>)
    }
    function handlePayment_option(id, payment_option) {
        return (<>
            { payment_option? 
            <div style={{width : '130px'}}>
                <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, payment_option: e.target.value });
                        }}
                        value={inputs.payment_option}
                    />
                    </div>
                </div> :
                <div style={{width : '130px'}}>
                    <div>
                    <InputArea
                        onChange={e => {
                            setInputs({ ...inputs, payment_option: e.target.value });
                        }}
                        value={inputs.payment_option}
                    />
                    </div>
                </div>
            }
        </>)
    }

    const searchByInvoice = (e) => {
        setSelectedUsers([])
        if (e.target.value === ''){
            setRows2(rowsOriginal)
        } else {
            setRows2(rows2.filter(row => row.data.invoiceId?.includes(e.target.value.trim())));
        }
    }

    return (
        <Container>
            <Backdrop show={showModal}>
            <ModalContainer>
                {
                 <ExitButton
                            onClick={() => closeModal()}
                        >
                            <img
                                alt={'Zatvori'}
                                src={require('../images/exit.png')}
                            />
                        </ExitButton>
                }
                <TitleContainer>
                    <h2>
                        Odaberite edukaciju
                    </h2>
                    <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={selectedRegistration} onChange={handleSelectSeminarRegistration}>
                    <option value={'default'}>Izaberite edukaciju</option>
                    {seminarOptions.length > 0
                        ? seminarOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item.title}>{item.title}</option>
                        ))
                        : false
                    }
                    </select>
                    <h6>
                        Prijava na edukaciju
                    </h6>
                </TitleContainer>

                <ContentContainer show={optionSelected}>
                <TextInput
                    value={registrationInputs.user}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, user: text })}
                    placeholder={'ime učesnika'}
                    label={'Ime i prezime:'}
                />
                <TextInput
                    value={registrationInputs.company}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, company: text })}
                    placeholder={'firma'}
                    label={'Firma:'}
                />
                <TextInput
                    value={registrationInputs.email}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, email: text })}
                    placeholder={'email'}
                    label={'Email:'}
                />
                <TextInput
                    value={registrationInputs.address}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, address: text })}
                    placeholder={'adresa'}
                    label={'Adresa:'}
                />
                <TextInput
                    value={registrationInputs.city}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, city: text })}
                    placeholder={'grad'}
                    label={'Grad:'}
                />
                <TextInput
                    value={registrationInputs.zip}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, zip: text })}
                    placeholder={'zip'}
                    label={'Zip:'}
                />
                <TextInput
                    value={registrationInputs.phone}
                    onChange={text => setRegistrationInputs({ ...registrationInputs, phone: text })}
                    placeholder={'telefon'}
                    label={'Telefon:'}
                />
                <select className="select-box" style={{ width: '30%', marginTop: 15, height: 35 }} value={registrationInputs.payment} onChange={e => setRegistrationInputs({ ...registrationInputs, payment: e.target.value })}>
                    <option value={''}>Opcija plaćanja:</option>
                    {optionSelected
                        ? seminarOptions.find(seminar => seminar.title === registrationInputs.seminar_name).prices.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                        : false
                    }
                </select>
                <select style={{ width: '30%', marginTop: 15, height: 35 }} value={registrationInputs.termin} onChange={e => setRegistrationInputs({ ...registrationInputs, termin: e.target.value })}>
                    <option value={''}>Termin:</option>
                    {optionSelected
                        ? seminarOptions.find(seminar => seminar.title === registrationInputs.seminar_name).times.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item}>{item}</option>
                        ))
                        : false
                    }
                </select>
                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '10px', marginBottom: '5px' }}>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop:  '5px', marginBottom: '10px' }}>
                    <AddButton onClick={() => handleNewSubscriptionSubmit()}>
                        <i className="fas fa-plus-circle"></i>
                        <h1>Dodaj</h1>
                    </AddButton>
                </div>
                </ContentContainer>
            </ModalContainer>
            </Backdrop>
            <Backdrop show={showModalInvoice}>
            <ModalContainer style={{ padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: 'white' }}>
                    <ExitButton onClick={() => closeModalInvoice()} style={{ float: 'right', cursor: 'pointer' }}>
                        <img alt={'Zatvori'} src={require('../images/exit.png')} />
                    </ExitButton>
                    <TitleContainer>
                        <h2 style={{ fontSize: '1.5rem', marginBottom: '20px', color: '#333' }}>
                            Odabrani emailovi
                        </h2>
                    </TitleContainer>
                    <Table style={{ marginBottom: 20 }} key={`seminar_data_${0}`}>
                        <tbody>
                            <tr style={{ maxWidth: 300 }}>
                                <th style={{ maxWidth: '10%', color: 'black' }}>Email korisnika:</th>
                            </tr>
                            {selectedUsers.length && showModalInvoice  > 0
                                ? selectedUsers.map((item, index) => {
                                    const user = rows2.find(row => row.id === item);
                                     return <tr key={'item_id_' + index}>
                                        <td style={{ maxwith: '30%' }}>
                                            <TextInput
                                                value={user.data.email}
                                                disaled={true}
                                            /></td>
                                    </tr>

                            })
                                : false
                            }</tbody>
                    </Table>
                    <div style={{ marginTop: '25px' }}>
                            <label htmlFor="fileInput">Upload File:  </label>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                            />
                        </div>
                    <AddButton style={{ marginTop: '25px', marginBottom: '25px' }} onClick={() => handleSendInvoiceButton()}>
                            <i className="fas fa-paper-plane"></i>
                            <h1>Pošalji</h1>
                        </AddButton>
                </ModalContainer>
            </Backdrop>
            <div style={{ display: "flex", alignItems: "center" }}>
    <PageTitle>Prijavljeni korisnici</PageTitle>
    {rows2.length > 0 && (
        <>
            {/* Dugme za preuzimanje CSV fajla */}
            <button
                style={{
                    marginLeft: 20,
                    padding: "10px 15px",
                    backgroundColor: "#3f51b5",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                }}
                onClick={downloadCSV}
            >
                Preuzmi CSV
            </button>

            {/* Dugme za preuzimanje Excel fajla */}
            <button
                style={{
                    marginLeft: 10, /* Manji razmak između dugmadi */
                    padding: "10px 15px",
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                }}
                onClick={downloadExcel}
            >
                Preuzmi Excel
            </button>
        </>
    )}
</div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <select style={{ width: '30%', marginTop: 15, height: 35 }} onChange={e => handleSelectSeminar(e.target.value)}>
                    <option value={null}>Izaberite edukaciju</option>
                    {seminarOptions.length > 0
                        ? seminarOptions.map((item, index) => (
                            <option key={`seminar_option_${index}`} value={item.title}>{item.title}</option>
                        ))
                        : false
                    }
                </select>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{marginRight: '10px'}}>
                        <AddButton onClick={() => handleSendInvoice()}>
                            <i className="fas fa-plus-circle"></i>
                            <h1>Posalji fakturu</h1>
                        </AddButton>
                    </div>
                    <div>
                        <AddButton onClick={() => handleNewSubscription()}>
                            <i className="fas fa-plus-circle"></i>
                            <h1>Dodaj</h1>
                        </AddButton>
                    </div>
                </div>
            </div>
            <QuestionsContainer>
                <div>
                <h4 style={{ color: '#f1f1f1'}}>
  Seminar: {selectedOption}{" "}
  {selectedOption ? `(${rows2.filter(row => row.data.status !== 'otkazano').length})` : false}
</h4>
                    <select style={{ width: '10%', height: 35, marginRight: 20  }} value={selectedFilter} onChange={handleSelectFilter}>
                        <option value={'default'}>Odaberite opciju za filtriranje</option>
                        <option value={'placeno'}>Plaćeno</option>
                        <option value={'neplaceno'}>Neplaćeno</option>
                        <option value={'otkazano'}>Otkazano</option>
                        <option value={'ugovor/odluka'}>ugovor/odluka</option>
                        <option value={'snimak'}>snimak</option>
                        <option value={'fakturisano'}>fakturisano</option>
                    </select>
                    <select style={{ width: '15%', height: 35, marginRight: 20 }} value={selectedSort} onChange={handleSelectSort}>
                        <option value={'default'}>Odaberite opciju za sortiranje</option>
                        <option value={'datum'}>Datum prijave</option>
                        <option value={'ime'}>Ime i prezime</option>
                    </select>
                    <input id='search' style={{height: 30, width: '15%'}} disabled={!rows2.length > 0} placeholder='Pretraži po predračunu' onChange={(e) => {searchByInvoice(e)}} />
                </div>
                <Table style={{ marginBottom: 20 }} rows2={rows2} key={`seminar_data_${0}`}>
                <tbody>
                            <tr style={{ maxWidth: 300 }}>
                                <th style={{ maxWidth: '10%' }}>Kompanija:</th>
                                 <th style={{ maxWidth: '10%' }}>Grad: </th> 
                                <th style={{ maxWidth: '10%' }}>Opcija plaćanja: </th>
                                <th style={{ maxWidth: '10%' }}>Ime i prezime: </th>
                                <th style={{ maxWidth: '10%' }}>Email: </th>
                                <th style={{ maxWidth: '10%' }}>Telefon: </th>
                                <th style={{ maxWidth: '10%' }}>Datum prijave: </th>
                                <th style={{ maxWidth: '10%' }}>Termin: </th>
                                <th style={{ maxWidth: '10%' }}>Adresa: </th> 
                                <th style={{ maxWidth: '10%' }}>Predracun: </th>
                                <th style={{ maxWidth: '10%' }}>Poštanski: </th> 
                                <th style={{ maxWidth: '10%' }}>Status</th>
                                <th style={{ maxWidth: '10%' }}>Opis: </th>
                                <th style={{ width: '15%' }}>Status</th>
                                <th style={{ width: '15%' }}>izmjeni cijenu</th>
                                <th style={{ maxWidth: '10%' }}>Predracuni</th> {/* New column header for the link */}
                                <th style={{ maxWidth: '2%' }}>Označi</th>
                                
                           </tr>
                {rows2.length > 0
                    ? rows2.map((item, index) => (   
                           <tr style={{ maxWidth: 300, background: item.data.status === "plaćeno" ? "#E30710" : item.data.status === "snimak" ? "green" : "#2d4059" }} key={'item_id_' + index}>
                                
                                <td style={{maxwith: '10%'}}>{item.data.company}</td> 
                                <td style={{maxwith: '10%'}}>{item.data.city}</td>  
                                <td style={{maxwith: '10%'}}>{item.data.payment_option}</td>
                                <td style={{maxwith: '10%'}}>{item.data.user}</td>
                                <td style={{maxwith: '10%'}}>{item.data.email}</td>
                                <td style={{maxwith: '10%'}}>{item.data.phone}</td>
                                <td style={{maxwith: '10%'}}>{item.data.date}</td>
                                <td style={{maxwith: '10%'}}>{item.data.termin}</td>
                                <td style={{maxwith: '10%'}}>{item.data.address}</td>
                                <td style={{maxwith: '10%'}}>{item.data.invoiceId}</td>
                                <td style={{maxwith: '10%'}}>{item.data.zip}</td>
                                <td style={{maxwith: '10%'}}>{item.data.status}</td>
                                <td style={{maxwith: '10%'}}>{editPaid && rows2[updateRowId] === item ? handleDescription(item.id, item.data.description) : item.data.description}{editPaid && rows2[updateRowId] === item ?<div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}><div style={{ marginRight:'10px'}}><AddButton  onClick={() => handleAddButton(item.id)}><i className="fas fa-plus-circle"></i><h1>Dodaj</h1></AddButton></div><CancleButton onClick={() => {setEditPaid(false);setUpdateRowId('');setInputs({ ...inputs, description: ''});}}><i className="fas fa-ban"></i><h1>Ponisti</h1></CancleButton></div>:<UpdateButton onClick={() => handleUpdate(index)}><i className="fas fa-edit"></i></UpdateButton>}</td>
                                <td style={{ width: '15%' }}>
                                    {item.data.status === "plaćeno" ? HandleStatus2(item.id, item.data.status, item.data.user, item.data.email, selectedOption2) : (item.data.status === "sent" && item.data.acces_data_sent === "true") ? <div></div> : HandleStatus(item.id, item.data.status, item.data.user, item.data.email, selectedOption)}
                                </td> 
                                <td style={{maxwith: '10%'}}>
                                    {editPayment_option && rows2[updateRowId] === item ? handlePayment_option(item.id, item.data.payment_option) : item.data.payment_option}{editPayment_option && rows2[updateRowId] === item ?<div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}><div style={{ marginRight:'10px'}}><AddButton  onClick={() => handleAddButtonpayment(item.id)}><i className="fas fa-plus-circle"></i><h1>Dodaj</h1></AddButton></div><CancleButton onClick={() => {setEditPayment_option(false);setUpdateRowId('');setInputs({ ...inputs, payment_option: ''});}}><i className="fas fa-ban"></i><h1>Ponisti</h1></CancleButton></div>:<UpdateButton onClick={() => handleUpdate(index)}><i className="fas fa-edit"></i></UpdateButton>}</td>
                                    <td style={{ maxHeight: '10%' }}>
                        <a href={`https://revicon.info/api/get-pdf?token=${item.id}`} target="_blank" rel="noopener noreferrer">
                            PDF Link
                        </a>
                    </td>
                    <td>
                                    <input 
                                    type="checkbox" 
                                    checked={selectedUsers.includes(item.id)} 
                                    onChange={() => handleRowSelect(item.id)} 
                                    />
                                </td>
                            </tr>   
                       
                    ))
                    : false
                }</tbody></Table>

            </QuestionsContainer>
        </Container>
    );
}

export default RegisteredUsers;
